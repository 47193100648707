import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import logo from "../images/logo.png"
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import CloseIcon from '@mui/icons-material/Close';
const NavBar = () => {
    const [activeLink, setActiveLink] = useState('Home');
    const location = useLocation();
    const currentPath = location.pathname.replace('/', '');
    const handleLinkClick = (link) => {
        setActiveLink(link);
        window.scrollTo(0, 0)
    };
    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        if (currentPath === 'social-links') {
            setActiveLink('Social');
        }
        if (currentPath === 'career') {
            setActiveLink('Career');
        }
        if (currentPath === 'service') {
            setActiveLink('Service');
        }
        if (currentPath === 'about') {
            setActiveLink('About');
        }
        if (currentPath === 'testimonial') {
            setActiveLink('Testimonial');
        }
        if (currentPath === 'contact') {
            setActiveLink('contact');
        }
    }, [currentPath])
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Call handleScroll on mount
        handleScroll();

        window.addEventListener('scroll', handleScroll);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className={`header ${isScrolled ? 'scrolled' : ''}`} style={{ position: "fixed", width: "100%", zIndex: "999", background: isScrolled ? 'white ' : 'transparent', }}>
            <nav style={{ padding: "0.3rem 1.5rem" }}>
                <div className="nav__logo"><img src={logo} /></div>
                <ul className="nav__links">
                    <li className={`link ${activeLink === 'Home' ? 'active' : ''}`}><Link onClick={() => handleLinkClick('Home')} to="/">Home</Link></li>
                    <li className={`link ${activeLink === 'Service' ? 'active' : ''}`}><Link onClick={() => handleLinkClick('Service')} to="/service">Services</Link></li>
                    <li className={`link ${activeLink === 'About' ? 'active' : ''}`}><Link onClick={() => handleLinkClick('About')} to="/about">About Us</Link></li>

                    <li className={`link ${activeLink === 'Testimonial' ? 'active' : ''}`}><Link onClick={() => handleLinkClick('Testimonial')} to="/testimonial">Testimonials</Link></li>
                    <li className={`link ${activeLink === 'Career' ? 'active' : ''}`}><Link onClick={() => handleLinkClick('Career')} to="/career">Careers</Link></li>
                    <li className={`link ${activeLink === 'Social' ? 'active' : ''}`}><Link onClick={() => handleLinkClick('Social')} to="/social-links">Social Links</Link></li>
                    <li style={{ background: "#4C9BE8", color: "white", padding: "10px", borderRadius: "4.44px" }} className="btn_link"><Link to="/contact" className="nav__btn">Contact Us</Link></li>
                </ul>
                <MenuIcon onClick={toggleDrawer} className='menu' />
            </nav>
            <div>
                <Drawer
                    open={isOpen}
                    onClose={toggleDrawer}
                    className='drawer'
                    direction='right'
                >
                    <div style={{ position: "absolute", top: "10px", right: "20px" }}>
                        <CloseIcon style={{ cursor: "pointer" }} onClick={toggleDrawer} />
                    </div>
                    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "37px", background: "#081432" }}>
                        <div className="nav__logo"><img src={logo} /></div>
                        <div>
                            <ul className="nav__linkss" style={{ gap: "2rem" }}>
                                <li className={`link ${activeLink === 'Home' ? 'active' : ''}`}><Link onClick={() => handleLinkClick('Home')} to="/">Home</Link></li>
                                <li className={`link ${activeLink === 'Service' ? 'active' : ''}`}><Link onClick={() => handleLinkClick('Service')} to="/service">Services</Link></li>
                                <li className={`link ${activeLink === 'About' ? 'active' : ''}`}><Link onClick={() => handleLinkClick('About')} to="/about">About Us</Link></li>

                                <li className={`link ${activeLink === 'Testimonial' ? 'active' : ''}`}><Link onClick={() => handleLinkClick('Testimonial')} to="/testimonial">Testimonials</Link></li>
                                <li className={`link ${activeLink === 'Career' ? 'active' : ''}`}><Link onClick={() => handleLinkClick('Career')} to="/career">Careers</Link></li>
                                <li className={`link ${activeLink === 'Social' ? 'active' : ''}`}><Link onClick={() => handleLinkClick('Social')} to="/social-links">Social Links</Link></li>
                                <li style={{ background: "#4C9BE8", color: "white", padding: "10px", borderRadius: "4.44px" }} className="btn_link"><Link to="/contact" className="nav__btn">Contact Us</Link></li>
                                <li style={{ background: "#4C9BE8", color: "white", padding: "10px", borderRadius: "4.44px" }} className="btn_link"><Link to="/contact" className="nav__btn">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                </Drawer>
            </div>
        </div>
    )
}

export default NavBar
