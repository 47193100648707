/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import logo from "../images/logo.png"
import code from "../images/code.png"
import p1 from "../images/p1.png"
import p2 from "../images/p2.png"
import p3 from "../images/p3.png"
import p4 from "../images/p4.png"
import p5 from "../images/p5.png"
import p6 from "../images/p6.png"
import p7 from "../images/p7.png"
import p8 from "../images/p8.png"
import v1 from "../images/v1.png"
import v2 from "../images/v2.png"
import v3 from "../images/v3.png"
import picture1 from "../images/Picture1.jpg"
import picture2 from "../images/Picture2.png"
import picture3 from "../images/Picture3.jpg"
import picture4 from "../images/Picture4.jpg"
import grp1 from "../images/ams.png"
import grp2 from "../images/amatt.png"
import grp3 from "../images/produc.png"
import grp4 from "../images/dm.png"
import grp5 from "../images/erp.png"
import grp6 from "../images/crm.png"
import grp7 from "../images/testing.png"
import grp8 from "../images/mapp.png"
import grp9 from "../images/cms.png"
import preview from "../images/preview 1.png"
import serviceback from "../images/curve home.png"
import { Link, useNavigate } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ce from "../video/ce.mp4"


import { Popover, Typography } from '@mui/material'
import Slider from 'react-slick'
import NavBar from './NavBar'
import Footer from './Footer'
import DotAnimation from './DotAnimation'
var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 1207,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 930,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const navigate = useNavigate()
  const handleClickNavigate = () => {
    navigate("/service")
    window.scrollTo(0, 0)
  }
  return (
    <>
      <DotAnimation />
      <div className='lgradient'>
        <NavBar />
        <section className="container" style={{ paddingTop: "89px" }}>
          <div className="content__container">
            <h1>
              <span className="heading__1">We <span className="code"> code</span> your</span><br />
              <span className="heading__2">thoughts</span>
            </h1>
            <p>
              Empower your business with cost-effective solutions. From website design to AMS Engagements, we've got you covered.
            </p>
          </div>
          <div className="image__container">
            <img src={code} />
          </div>
        </section>

      </div>
      <section id='partners'>
        <div className='partners'>
          <div className="image__container">
          <video src={ce} autoPlay muted controls></video>

          </div>
        </div>
      </section>
      <section id='partners'>
        <div className='partners'>
          <div className='head-partner'>
            <h1>Our <span className='code'>Partners</span></h1>
          </div>
          <div className='overflow' style={{ margin: "40px 0px", overflowX: "auto" }}>
            <div className='partner_image_div' style={{ display: "flex", gap: "54px" }}>
              <div>
                <img src={picture1} style={{ height: "4rem", width: "4rem" }} />
                <p style={{ fontSize: "13px", fontWeight: "bold", textAlign: "center", marginTop: "5px" }}>BBI</p>
              </div>
              <div>
                <img src={picture2} style={{ height: "4rem", width: "4rem" }} />
                <p style={{ fontSize: "13px", fontWeight: "bold", textAlign: "center", marginTop: "5px" }}>Title 21</p>
              </div>
              <div>
                <img src={picture3} style={{ height: "4rem", width: "4rem" }} />
                <p style={{ fontSize: "13px", fontWeight: "bold", textAlign: "center", marginTop: "5px" }}>NIC</p>
              </div>
              <div>
                <img src={picture4} style={{ height: "4rem", width: "4rem" }} />
                <p style={{ fontSize: "13px", fontWeight: "bold", textAlign: "center", marginTop: "5px" }}>ASCI</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='destination'>
        <div className='destneeds'>
          <div>
            <img src={preview} style={{ width: "100%" }} />
          </div>
          <div className='right_dest'>
            <h1>Your only <span>destination</span> for all your needs</h1>
            <div style={{ margin: "50px 0px" }}>
              <div className='points'>
                <div style={{ marginTop: "4px" }}><img src={v1} /></div>
                <div className='pt-1'>
                  <h5>Ultra Fast & Secure</h5>
                  <span>Implement advanced security measures to ensure a secure and swift user experience.</span>
                </div>
              </div>
              <div className='points'>
                <div style={{ marginTop: "4px" }}><img src={v2} /></div>
                <div className='pt-1'>
                  <h5>Allow Customization</h5>
                  <span>Enjoy flexibility in design and functionality to align the platform precisely with your requirements.</span>
                </div>
              </div>
              <div className='points'>
                <div style={{ marginTop: "4px" }}><img src={v3} /></div>
                <div className='pt-1'>
                  <h5>Smart Contract</h5>
                  <span>Leverage the power of smart contracts for automated, transparent, and efficient transaction execution.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='services'>
        <div className='services'>
          <div className='head-services'>
            <h1>Our <span className='code'>Services</span></h1>
          </div>
          <div style={{ margin: "60px 60px" }}>
            <Slider {...settings} >
              <div onClick={handleClickNavigate} className='cards-data'>
                <div style={{ margin: "0px 6px", background: "white", height: "290px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "30px" }}>
                  <div>
                    <div style={{ background: "#EEF5FD", display: "flex", alignItems: "center", justifyContent: "center", padding: "20px", borderRadius: "999px" }}>
                      <img src={grp8} style={{ height: "52px", borderRadius: "999px" }} />
                    </div>
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    <h5 className='newH5'>Mobile and web app development</h5>
                  </div>
                </div>
                {/* <div className="overlay"></div> */}
              </div>
              <div onClick={handleClickNavigate} className='cards-data'>
                <div style={{ margin: "0px 6px", background: "white", height: "290px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "30px" }}>
                  <div>
                    <div style={{ background: "#EEF5FD", display: "flex", alignItems: "center", justifyContent: "center", padding: "20px", borderRadius: "999px" }}>
                      <img src={grp4} style={{ height: "55px", borderRadius: "999px" }} />
                    </div>
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    <h5 className='newH5'>Data migration and integrations</h5>
                  </div>
                </div>
              </div>
              <div onClick={handleClickNavigate} className='cards-data'>
                <div style={{ margin: "0px 6px", background: "white", height: "290px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "30px" }}>
                  <div>
                    <div style={{ background: "#EEF5FD", display: "flex", alignItems: "center", justifyContent: "center", padding: "20px", borderRadius: "999px" }}>
                      <img src={grp9} style={{ height: "55px", borderRadius: "999px" }} />
                    </div>
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    <h5 className='newH5'>CMS</h5>
                  </div>
                </div>
              </div>
              <div onClick={handleClickNavigate} className='cards-data'>
                <div style={{ margin: "0px 6px", background: "white", height: "290px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "30px" }}>
                  <div>
                    <div style={{ background: "#EEF5FD", display: "flex", alignItems: "center", justifyContent: "center", padding: "20px", borderRadius: "999px" }}>
                      <img src={grp5} style={{ height: "55px", borderRadius: "999px" }} />
                    </div>
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    <h5 className='newH5'>ERP</h5>
                  </div>
                </div>
              </div>
              <div onClick={handleClickNavigate} className='cards-data'>
                <div style={{ margin: "0px 6px", background: "white", height: "290px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "30px" }}>
                  <div>
                    <div style={{ background: "#EEF5FD", display: "flex", alignItems: "center", justifyContent: "center", padding: "20px", borderRadius: "999px" }}>
                      <img src={grp6} style={{ height: "55px", borderRadius: "999px" }} />
                    </div>
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    <h5 className='newH5'>CRM</h5>
                  </div>
                </div>
              </div>
              <div onClick={handleClickNavigate} className='cards-data'>
                <div style={{ margin: "0px 6px", background: "white", height: "290px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "30px" }}>
                  <div>
                    <div style={{ background: "#EEF5FD", display: "flex", alignItems: "center", justifyContent: "center", padding: "20px", borderRadius: "999px" }}>
                      <img src={grp7} style={{ height: "52px", borderRadius: "999px", width: "55px" }} />
                    </div>
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    <h5 className='newH5'>Testing services</h5>
                  </div>
                </div>
              </div>
              <div onClick={handleClickNavigate} className='cards-data'>
                <div style={{ margin: "0px 6px", background: "white", height: "290px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "30px" }}>
                  <div>
                    <div style={{ background: "#EEF5FD", display: "flex", alignItems: "center", justifyContent: "center", padding: "20px", borderRadius: "999px" }}>
                      <img src={grp2} style={{ height: "52px", borderRadius: "999px", width: "55px" }} />
                    </div>
                  </div>
                  <div style={{ fontSize: "18px", textAlign: "center" }}>
                    <h5 className='newH5'>Application Modernisation and technology transformations</h5>
                  </div>
                </div>
              </div>
              <div onClick={handleClickNavigate} className='cards-data'>
                <div style={{ margin: "0px 6px", background: "white", height: "290px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "30px" }}>
                  <div>
                    <div style={{ background: "#EEF5FD", display: "flex", alignItems: "center", justifyContent: "center", padding: "20px", borderRadius: "999px" }}>
                      <img src={grp1} style={{ height: "55px", borderRadius: "999px" }} />
                    </div>
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    <h5 className='newH5'>AMS engagements</h5>
                  </div>
                </div>
              </div>
              <div onClick={handleClickNavigate} className='cards-data'>
                <div style={{ margin: "0px 6px", background: "white", height: "290px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "30px" }}>
                  <div>
                    <div style={{ background: "#EEF5FD", display: "flex", alignItems: "center", justifyContent: "center", padding: "20px", borderRadius: "999px" }}>
                      <img src={grp3} style={{ height: "55px", borderRadius: "999px" }} />
                    </div>
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    <h5 className='newH5'>Product Implementation</h5>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='popcontent'>
          <Typography style={{ fontSize: "10px", textAlign: "center" }} sx={{ p: 2 }}>Lorem ipsum dolor sit amet, consectetur<br /> adipiscing elit. Nulla scelerisque.</Typography>
          <div>
            <Typography style={{ fontSize: "10px", padding: "5px 16px" }} >Wilson Bergson</Typography>
            <Typography style={{ fontSize: "10px", padding: "0px 16px", paddingBottom: "10px" }} >Marketing Manager</Typography>
          </div>
        </div>
      </Popover>
      <div style={{ marginTop: "-47px" }}>
        <Footer />
      </div>
    </>
  )
}

export default Home
