/* eslint-disable jsx-a11y/alt-text */
import { Avatar, Box, Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import NavBar from './NavBar'
import serv from "../images/services.png"
import Footer from './Footer'
import career from "../images/work.png"
import w1 from "../images/w1.png"
import w2 from "../images/Environment Icon.png"
import w3 from "../images/Sheild Icon.png"
import star from "../images/star.png"
import serv1 from "../images/serv 1.png"
import serv2 from "../images/serv 2.png"
import serv3 from "../images/serv 3.png"
import serv4 from "../images/serv 4.png"
import serv5 from "../images/serv 5.png"
import serv6 from "../images/serv 6.png"
import serv7 from "../images/serv 7.png"
import serv8 from "../images/serv 8.png"
import { useNavigate } from 'react-router'
import gif from "../images/gif.gif"

const Service = () => {
    const [isHovered, setHovered] = useState(false);
    const [isHovered1, setHovered1] = useState(false);
    const [isHovered2, setHovered2] = useState(false);
    const [isHovered3, setHovered3] = useState(false);
    const [isHovered4, setHovered4] = useState(false);
    const [isHovered5, setHovered5] = useState(false);
    const [isHovered6, setHovered6] = useState(false);
    const [isHovered7, setHovered7] = useState(false);

    const handleMouseOver = () => {
        setHovered(true);
    };

    const handleMouseOut = () => {
        setHovered(false);
    };

    const handleMouseOver1 = () => {
        setHovered1(true);
    };

    const handleMouseOut1 = () => {
        setHovered1(false);
    };

    const handleMouseOver2 = () => {
        setHovered2(true);
    };

    const handleMouseOut2 = () => {
        setHovered2(false);
    };

    const handleMouseOver3 = () => {
        setHovered3(true);
    };

    const handleMouseOut3 = () => {
        setHovered3(false);
    };
    const handleMouseOver4 = () => {
        setHovered4(true);
    };

    const handleMouseOut4 = () => {
        setHovered4(false);
    };
    const handleMouseOver5 = () => {
        setHovered5(true);
    };

    const handleMouseOut5 = () => {
        setHovered5(false);
    };
    const handleMouseOver6 = () => {
        setHovered6(true);
    };

    const handleMouseOut6 = () => {
        setHovered6(false);
    };
    const handleMouseOver7 = () => {
        setHovered7(true);
    };

    const handleMouseOut7 = () => {
        setHovered7(false);
    };
    const navigate = useNavigate()
    const handlelButtonClick = () => {
        navigate("/contact")
    }


    const imageSource = isHovered ? serv1 : serv1;
    const imageSource1 = !isHovered1 ? serv2 : serv2;
    const imageSource2 = !isHovered2 ? serv3 : serv3;
    const imageSource3 = !isHovered3 ? serv4 : serv4;
    const imageSource4 = !isHovered4 ? serv5 : serv5;
    const imageSource5 = !isHovered5 ? serv6 : serv6;
    const imageSource6 = !isHovered6 ? serv7 : serv7;
    const imageSource7 = !isHovered7 ? serv8 : serv8;
    return (
        <>
            <Box style={{ position: "relative" }} className='social-links-header'>
                <img src={serv} alt="" style={{ width: "100%", height: "43vh" }} />
                <div style={{ position: "absolute" }} className='flex'>
                    <Typography className='t2' sx={{ fontSize: { md: "2rem", sm: "1.5rem", sx: "1rem" } }} fontWeight='700'>Our <span className="code">Services</span></Typography>
                    <Typography className='t1' style={{ color: "#51565E" }} sx={{ fontSize: { md: "0.8rem", sm: "1.5rem", sx: "1rem" } }} fontWeight='700'>At Code Ethics, we specialize in a comprehensive range of services dedicated to enhancing your online presence and ensuring digital success. Our diverse offerings are meticulously crafted to meet the unique needs of your business, providing tailored solutions for optimal growth and visibility in the digital landscape.</Typography>
                </div>
                <div style={{ position: "absolute", top: "0px", left: "0px", right: "0px" }}>
                    <NavBar />
                </div>
            </Box>
            <section id="job services">
                <div className='carrer'>
                    <div className='c-below s-below'>
                        <div className='c-below-second numbersecon s-below-second-details'>
                            <div className='c-below-second-details data'>
                                <h6 className='code' style={{ fontSize: "2rem", marginBottom: "20px" }}>Application Modernization <br /> & Technology Transformation</h6>
                                <p className='pfirst' >

                                    Application modernization and technology
                                    transformation are crucial for companies
                                    to stay competitive in today's fast-paced
                                    digital landscape. It allows them to
                                    enhance operational efficiency, improve
                                    customer experience, and leverage
                                    emerging technologies for innovation and
                                    growth. Without it, companies risk
                                    becoming outdated and losing their
                                    competitive edge.
                                </p>
                                <Button onClick={handlelButtonClick} style={{ background: "white", border: "1px solid #4C9BE8", borderRadius: "0px", color: "#141414", margin: "20px 0px", fontWeight: "bold", padding: "7px 16px" }}>GET SERVICE</Button>
                            </div>
                        </div>
                        <div className={`c-below-first numberfirst s-first ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}><img className="c-below-img" src={imageSource} /></div>

                    </div>


                    <div className='c-below flexrevers s-below'>
                        <div className='c-below-first numberfirst num s-first' onMouseOver={handleMouseOver1} onMouseOut={handleMouseOut1}><img className="c-below-img" src={imageSource1} /></div>
                        <div className='c-below-second numbersecon s-below-second-details'>
                            <div className='c-below-second-details numbersecond data'>
                                <h6 className='code' style={{ fontSize: "2rem", marginBottom: "20px" }}>CRM</h6>
                                <p className='psecond'>

                                    CRM is crucial for companies as it helps
                                    them manage customer relationships
                                    effectively. It streamlines sales
                                    processes, enhances customer service,
                                    and improves marketing strategies.

                                    Code ethics helps companies with their
                                    customer data, with code ethic you can
                                    personalized interactions, boosts
                                    customer retention, and drives
                                    business growth. It’s an essential tool
                                    for staying competitive in today’s
                                    market.

                                </p>
                                <Button onClick={handlelButtonClick} style={{ background: "white", border: "1px solid #4C9BE8", borderRadius: "0px", color: "#141414", margin: "20px 0px", fontWeight: "bold", padding: "7px 16px" }}>GET SERVICE</Button>
                            </div>
                        </div>
                    </div>


                    <div className='c-below s-below'>
                        <div className='c-below-second numbersecon s-below-second-details'>
                            <div className='c-below-second-details data'>
                                <h6 className='code' style={{ fontSize: "2rem", marginBottom: "20px" }}>Web Application
                                    Development</h6>
                                <p className='pthird'>

                                    Web application development is a
                                    dynamic and rapidly evolving field. It involves the creation and maintenance
                                    of applications that are accessed
                                    through a web browser.
                                    We at code ethics use various
                                    programming languages, frameworks,
                                    and tools to build user-friendly and
                                    interactive web applications. The
                                    process includes designing, coding,
                                    testing, and deploying applications to
                                    ensure optimal performance and
                                    functionality

                                </p>
                                <Button onClick={handlelButtonClick} style={{ background: "white", border: "1px solid #4C9BE8", borderRadius: "0px", color: "#141414", margin: "20px 0px", fontWeight: "bold", padding: "7px 16px" }}>GET SERVICE</Button>
                            </div>
                        </div>
                        <div className='c-below-first numberfirst s-first num' onMouseOver={handleMouseOver2} onMouseOut={handleMouseOut2}><img className="c-below-img" src={imageSource2} /></div>

                    </div>


                    <div className='c-below flexrevers s-below'>
                        <div className='c-below-first numberfirst s-first' onMouseOver={handleMouseOver3} onMouseOut={handleMouseOut3}><img className="c-below-img" src={imageSource3} /></div>
                        <div className='c-below-second numbersecon s-below-second-details'>
                            <div className='c-below-second-details data'>
                                <h6 className='code' style={{ fontSize: "2rem", marginBottom: "20px" }}>CMS</h6>
                                <p className='pfourth'>

                                    Content Management Systems (CMS) are
                                    crucial for companies as they provide a
                                    centralized platform to manage and
                                    publish content efficiently. CMS allows
                                    businesses to easily update and control
                                    their website content, ensuring accurate
                                    and up-to-date information for
                                    customers.

                                    Additionally, CMS offers flexibility and
                                    scalability, empowering companies to
                                    adapt to changing market demands and
                                    stay competitive. Thus choosing code
                                    ethics will allow your company to have a
                                    better responsive CMS System.
                                </p>
                                <Button onClick={handlelButtonClick} style={{ background: "white", border: "1px solid #4C9BE8", borderRadius: "0px", color: "#141414", margin: "20px 0px", fontWeight: "bold", padding: "7px 16px" }}>GET SERVICE</Button>
                            </div>
                        </div>
                    </div>

                    <div className='c-below s-below'>
                        <div className='c-below-second numbersecon s-below-second-details'>
                            <div className='c-below-second-details data'>
                                <h6 className='code' style={{ fontSize: "2rem", marginBottom: "20px" }}>Data Migration & Integration</h6>
                                <p className='pthird'>

                                    Data migration and integration
                                    are crucial processes in modern
                                    businesses.

                                    They involve transferring and
                                    consolidating data from various
                                    sources into a unified system. This
                                    ensures seamless data flow,
                                    improved efficiency, and better
                                    decision-making capabilities.

                                </p>
                                <Button onClick={handlelButtonClick} style={{ background: "white", border: "1px solid #4C9BE8", borderRadius: "0px", color: "#141414", margin: "20px 0px", fontWeight: "bold", padding: "7px 16px" }}>GET SERVICE</Button>
                            </div>
                        </div>
                        <div className='c-below-first numberfirst s-first num' onMouseOver={handleMouseOver4} onMouseOut={handleMouseOut4}><img className="c-below-img" src={imageSource4} /></div>

                    </div>

                    <div className='c-below flexrevers s-below'>
                        <div className='c-below-first numberfirst s-first' onMouseOver={handleMouseOver5} onMouseOut={handleMouseOut5}><img className="c-below-img" src={imageSource5} /></div>
                        <div className='c-below-second numbersecon s-below-second-details'>
                            <div className='c-below-second-details data'>
                                <h6 className='code' style={{ fontSize: "2rem", marginBottom: "20px" }}>Testing Services</h6>
                                <p className='pfourth'>
                                    The testing services are important part
                                    in any company as they ensure the
                                    quality and reliability of their products
                                    or services.

                                    Testing with us will help you to identify
                                    and fix any issues or bugs, ensuring a
                                    smooth user experience and preventing
                                    potential financial losses or damage to
                                    the company’s reputation.
                                </p>
                                <Button onClick={handlelButtonClick} style={{ background: "white", border: "1px solid #4C9BE8", borderRadius: "0px", color: "#141414", margin: "20px 0px", fontWeight: "bold", padding: "7px 16px" }}>GET SERVICE</Button>
                            </div>
                        </div>
                    </div>

                    <div className='c-below s-below'>
                        <div className='c-below-second numbersecon s-below-second-details'>
                            <div className='c-below-second-details data'>
                                <h6 className='code' style={{ fontSize: "2rem", marginBottom: "20px" }}>AMS Engagements</h6>
                                <p className='pthird'>
                                    AMS engagement is crucial for
                                    companies as it fosters customer
                                    satisfaction, strengthens brand loyalty,
                                    and drives revenue growth. By actively
                                    engaging with customers through AMS,
                                    companies can gain valuable insights,
                                    address customer concerns, and
                                    provide personalized experiences. This
                                    not only enhances customer retention
                                    but also establishes a positive brand
                                    image, ultimately leading to long-term
                                    success.
                                </p>
                                <Button onClick={handlelButtonClick} style={{ background: "white", border: "1px solid #4C9BE8", borderRadius: "0px", color: "#141414", margin: "20px 0px", fontWeight: "bold", padding: "7px 16px" }}>GET SERVICE</Button>
                            </div>
                        </div>
                        <div className='c-below-first numberfirst s-first num' onMouseOver={handleMouseOver6} onMouseOut={handleMouseOut6}><img className="c-below-img" src={imageSource6} /></div>

                    </div>

                    <div className='c-below flexrevers s-below'>
                        <div className='c-below-first numberfirst s-first' onMouseOver={handleMouseOver7} onMouseOut={handleMouseOut7}><img className="c-below-img" src={imageSource7} /></div>
                        <div className='c-below-second numbersecon s-below-second-details'>
                            <div className='c-below-second-details data'>
                                <h6 className='code' style={{ fontSize: "2rem", marginBottom: "20px" }}>Product Implementation</h6>
                                <p className='pfourth'>

                                    Product implementation in a company is
                                    essential for various reasons. Firstly, it improves
                                    efficiency by automating processes and reducing
                                    manual work. Secondly, it enhances productivity
                                    by providing tools and features that streamline
                                    tasks.

                                    Additionally, product implementation ensures
                                    better customer satisfaction, as it enables the
                                    company to deliver high-quality products and
                                    services. Lastly, it helps the company stay
                                    competitive in the market by keeping up with
                                    technological advancements and industry
                                    standards.

                                </p>
                                <Button onClick={handlelButtonClick} style={{ background: "white", border: "1px solid #4C9BE8", borderRadius: "0px", color: "#141414", margin: "20px 0px", fontWeight: "bold", padding: "7px 16px" }}>GET SERVICE</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="career">
                <div className='carrer'>
                    <div className='c-h5'>
                        <h5><span style={{fontWeight:"900"}}>How We</span> <span className='code'>Work</span></h5>
                    </div>
                </div>
                <div className='c-below' id="w-below" style={{ boxShadow: "none" }}>
                    <div className='c-below-first' style={{ boxShadow: "none" }}><img className='wimage' src={career} /></div>
                    <div className='c-below-second' style={{ boxShadow: "none" }}>
                        <div className='c-below-second-details' id="work">
                            <div>
                                <img src={w1} width={35} />
                            </div>
                            <div>
                                <h6>Strategic Consultation</h6>
                                <span> We initiate the process with a comprehensive strategic consultation, understanding your business goals and vision to tailor our digital solutions for optimal success.</span>
                            </div>

                        </div>
                        <div className='c-below-second-details' id="work">
                            <div>
                                <img src={w3} width={35} />
                            </div>
                            <div>
                                <h6>Collaborative Development</h6>
                                <span> Through collaborative development, we bring your ideas to life working closely with you at every step to ensure your vision is seamlessly integrated into the design, development, and implementation phases.</span>
                            </div>
                        </div>
                        <div className='c-below-second-details' id="work">
                            <div>
                                <img src={w2} width={35} />
                            </div>
                            <div>
                                <h6>Continuous Optimization</h6>
                                <span> Our commitment extends beyond the launch, we continually optimize and refine our solutions adapting to evolving industry trends and technologies to ensure your digital presence remains effective and impactful.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <Box sx={{ background: "linear-gradient(105.1deg, #FFFFFF 0%, #E5EAFC 49.99%, #4B61B8 99.99%)" }}>
                    <Box sx={{ padding: { md: '6rem 6rem 8rem 6rem', sm: '6rem 4rem 8rem 4rem', xs: '3rem 2rem 8rem 2rem' } }}>
                        <div className='head-partner'>
                            <h1 style={{ textAlign: "center" }}><span className='code'>Tech Stack</span> Expertise</h1>
                        </div>
                        <Box mt={5}>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box sx={{ borderRadius: "1rem", background: "white", paddingY: "2rem", height: "250px" }}>
                                        <Grid container justifyContent='center' spacing={3}>
                                            <Grid item xs={10}>
                                                <Box>
                                                    <Box sx={{ display: "flex", gap: { md: "1rem", sm: "0.8rem", xs: "0.5rem" }, padding: "2rem 0", flexWrap: "wrap" }} >
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                    </Box>
                                                    <Typography fontSize='0.8rem'>C, C#, Python, Java, Virtual
                                                        Reality, AI,
                                                        Cloud, Scala, .Net, React,
                                                        Angular, Vue, Next, Node, Graph
                                                        QL</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box sx={{ borderRadius: "1rem", background: "white", paddingY: "2.5rem", height: "250px" }}>
                                        <Grid container justifyContent='center' spacing={3}>
                                            <Grid item xs={10}>
                                                <Box>
                                                    <Box sx={{ display: "flex", gap: { md: "1rem", sm: "0.8rem", xs: "0.5rem" }, padding: "2rem 0", flexWrap: "wrap" }} >
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                    </Box>
                                                    <Typography fontSize='0.8rem'>Arc GIS</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box sx={{ borderRadius: "1rem", background: "white", paddingY: "2.5rem", height: "250px" }}>
                                        <Grid container justifyContent='center' spacing={3}>
                                            <Grid item xs={10}>
                                                <Box>
                                                    <Box sx={{ display: "flex", gap: { md: "1rem", sm: "0.8rem", xs: "0.5rem" }, padding: "2rem 0", flexWrap: "wrap" }} >
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                    </Box>
                                                    <Typography fontSize='0.8rem'>Quality Assurance & Analysis</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box sx={{ borderRadius: "1rem", background: "white", paddingY: "2.5rem", height: "250px" }}>
                                        <Grid container justifyContent='center' spacing={3}>
                                            <Grid item xs={10}>
                                                <Box>
                                                    <Box sx={{ display: "flex", gap: { md: "1rem", sm: "0.8rem", xs: "0.5rem" }, padding: "2rem 0", flexWrap: "wrap" }} >
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                    </Box>
                                                    <Typography fontSize='0.8rem'>Vtiger CRM, Chat Bot</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box sx={{ borderRadius: "1rem", background: "white", paddingY: "2.5rem", height: "250px" }}>
                                        <Grid container justifyContent='center' spacing={3}>
                                            <Grid item xs={10}>
                                                <Box>
                                                    <Box sx={{ display: "flex", gap: { md: "1rem", sm: "0.8rem", xs: "0.5rem" }, padding: "2rem 0", flexWrap: "wrap" }} >
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                    </Box>
                                                    <Typography fontSize='0.8rem'>Generative AI, ML</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box sx={{ borderRadius: "1rem", background: "white", paddingY: "2.5rem", height: "250px" }}>
                                        <Grid container justifyContent='center' spacing={3}>
                                            <Grid item xs={10}>
                                                <Box>
                                                    <Box sx={{ display: "flex", gap: { md: "1rem", sm: "0.8rem", xs: "0.5rem" }, padding: "2rem 0", flexWrap: "wrap" }} >
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                        <img src={star} width={20} />
                                                    </Box>
                                                    <Typography fontSize='0.8rem'>Data Bricks, Informatica, AWS</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                </Box>
            </section>
            <div style={{ position: "absolute", marginTop: "-3rem", width: "100%" }}>
                <Footer />
            </div>

        </>
    )
}

export default Service
