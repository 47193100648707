import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import SocialLinks from './components/SocialLinks';
import { Route, Routes } from 'react-router';

import Contact from './components/Contact';

import Career from './components/Career';
import Service from './components/Service';
import About from './components/About';
import Testimonial from './components/Testimonial';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/social-links" element={<SocialLinks />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/service" element={<Service />} />
        <Route path="/about" element={<About />} />
        <Route path="/testimonial" element={<Testimonial />} />
      </Routes>
    </div>
  );
}

export default App;
