import { Box, Button, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import NavBar from './NavBar'
import dynamic from "../images/dynamic.png"
import career from "../images/career.png"
import i1 from "../images/Image.png"
import i2 from "../images/vtiger.jpg"
import i3 from "../images/Column.png"
import i4 from "../images/image 164.png"
import i5 from "../images/Rectangle 3841.png"
import Footer from './Footer'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { styled } from '@mui/material/styles';
import { AccountCircle } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const Career = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        jobRole: '',
        cv: '',
        aboutYourself: '',
        file: null
    });
    const fileInputRef = useRef(null);
    const handleChange = (field) => (event) => {
        setFormData({
            ...formData,
            [field]: event.target.value,
        });
    };
    const sendEmail = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        const fileInput = formData.file;
        try {
            if (fileInput) {

                const serviceID = "service_42scdsw";
                const templateID = "template_rsh28zt";

                const params = {
                    sendername: `${formData.firstName} ${formData.lastName}`,
                    to: "shruti.pandey@codeethics.in",
                    subject: "Apply Now Form Details",
                    replyto: formData.email,
                    message: `
                          First Name: ${formData.firstName}
                          Last Name : ${formData.lastName}
                          Mobile Number: ${formData.phoneNumber}
                          Email: ${formData.email}
                          Job Role: ${formData.jobRole}
                          CV:${formData.cv}
                          About myself:${formData.aboutYourself}
                        `,
                };
                await emailjs.send(serviceID, templateID, params, "rLi115x7NbmnZdlX-");
                toast.success('Email sent successfully!');

            }
        } catch (error) {
            console.error("Error sending email:", error);
        } finally {
            setSubmitting(false);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (formData) {
            sendEmail(event)
        }
    };
    const handleFileClick = () => {
        fileInputRef.current.click();
    };
    const handleFileChange = (event) => {
        // Handle file selection
        const file = event.target.files[0];
        setFormData({
            ...formData,
            cv: file.name,
            file
        });
    };
    const [submitting, setSubmitting] = useState(false);

    return (
        <>
            <Box className='social-links-header'>
                <img src={dynamic} alt="" style={{ width: "100%", height: "40vh" }} />
                <Typography sx={{ fontSize: { md: "2rem", sm: "1.5rem", sx: "1rem", position: "absolute",} }} fontWeight='700'>Join Our <span className="code">Dynamic Team </span> at Code Ethics</Typography>
                <div style={{ position: "absolute", top: "0px", left: "0px", right: "0px" }}>
                    <NavBar />
                </div>
            </Box>
            <ToastContainer position="top-right" autoClose={5000} />
            <section id="career">
                <div className='carrer'>
                    <div className='c-h5'>
                        <h5 style={{fontWeight:900 }}><span style={{ color: "#4B61B8" }}>Why Choose</span> a Career with Code Ethics </h5>
                    </div>
                </div>
                <div className='c-below' style={{ boxShadow: "none" }}>
                    <div className='c-below-first' style={{ boxShadow: "none" }}><img src={career} /></div>
                    <div className='c-below-second' style={{ boxShadow: "none" }}>
                        <div className='c-below-second-details'>
                            <h6 style={{fontWeight:900}}>Innovation and Growth</h6>
                            <span> Be part of a company that is at the forefront of digital solutions, fostering an environment that encourages innovation and continuous learning.</span>
                        </div>
                        <div className='c-below-second-details'>
                            <h6 style={{fontWeight:900}}>Collaborative Culture</h6>
                            <span> Join a team of professionals who believe in collaboration, open communication, and mutual support, creating a positive and inspiring work culture.</span>
                        </div>
                        <div className='c-below-second-details'>
                            <h6 style={{fontWeight:900}}>Career Development</h6>
                            <span> We prioritize the professional growth of our team members, offering opportunities for skill enhancement and career advancement.</span>
                        </div>
                        <div className='c-below-second-details'>
                            <h6 style={{fontWeight:900}}>Client-Centric Approach</h6>
                            <span> At Code Ethics Webtech, we prioritize client satisfaction, and you'll have the chance to contribute to impactful projects that shape the digital success of our clients..</span>
                        </div>
                    </div>
                </div>
            </section>


            <section id="job">
                <div className='carrer'>
                    <div className='c-h5'>
                        <h5 style={{fontWeight:900 }}><span className='code'>Current Job</span> Openings</h5>
                    </div>
                    <div className='c-below' style={{ boxShadow: "none" }}>
                        <div className='c-below-second numbersecon'>
                            <div className='c-below-second-details'>
                                <h6 style={{ fontSize: "23px", marginBottom: "20px" }}>Web Developer</h6>
                                <span className='code'>Responsibilities</span><br />
                                <ul style={{ margin: "10px 20px" }}>
                                    <li style={{ margin: "5px 0px" }}>Develop and maintain responsive websites</li>
                                    <li style={{ margin: "5px 0px" }}>collaborate with cross-functional teams</li>
                                    <li style={{ margin: "5px 0px" }}>Ensure the highest quality of web development.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='c-below-first numberfirst' style={{ boxShadow: "none" }}><img className="c-below-img" src={i1} /></div>

                    </div>


                    <div className='c-below flexrevers' style={{ boxShadow: "none" }}>
                        <div className='c-below-first numberfirst num' style={{ boxShadow: "none" }}><img className="c-below-img" src={i2} /></div>
                        <div className='c-below-second numbersecon'>
                            <div className='c-below-second-details numbersecond'>
                                <h6 style={{ fontSize: "23px", marginBottom: "20px" }}>Vtiger CRM</h6>
                                <span className='code'>Responsibilities</span><br />
                                <ul style={{ margin: "10px 20px" }}>
                                    <li className='licareer' style={{ margin: "5px 0px", width: "350px" }}>It is a digital, centralized platform that organizes, automates all customer-facing activities.</li>
                                    <li style={{ margin: "5px 0px" }}>It helps business to streamline the operations</li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    {/* <div className='c-below' style={{boxShadow:"none"}}>
                        <div className='c-below-second numbersecon'>
                            <div className='c-below-second-details'>
                                <h6 style={{ fontSize: "23px", marginBottom: "20px" }}>Content Writer</h6>
                                <span className='code'>Responsibilities</span><br />
                                <ul style={{ margin: "10px 20px" }}>
                                    <li style={{ margin: "5px 0px" }}>Create engaging and SEO-friendly content for websites</li>
                                    <li style={{ margin: "5px 0px" }}>Blogs and marketing materials</li>
                                    <li style={{ margin: "5px 0px" }}>Ensuring brand consistency and audience engagement.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='c-below-first numberfirst' style={{boxShadow:"none"}}><img className="c-below-img" src={i4} /></div>

                    </div> */}


                    <div className='c-below'>
                        <div className='c-below-second numbersecon'>
                            <div className='c-below-second-details'>
                                <h6 style={{ fontSize: "23px", marginBottom: "20px" }}>App Developer</h6>
                                <span className='code'>Responsibilities</span><br />
                                <ul style={{ margin: "10px 20px" }}>
                                    <li className='licareer' style={{ margin: "5px 0px", width: "400px" }}>Design and develop customized applications for various platforms</li>
                                    <li className='licareer' style={{ margin: "5px 0px", width: "400px" }}>Ensuring functionality, usability, and a  seamless user experience.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='c-below-first numberfirst' style={{ boxShadow: "none" }}><img className="c-below-img" src={i3} /></div>

                    </div>
                </div>
            </section>
            <section id="job" className='onejob'>
                <div className='contacts'>
                    <div className='left-contact'>

                        <div style={{ color: "white", fontSize: "27px" }}>
                            <h5>Contact Information</h5>
                            <div className='contact-content'>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <p style={{ fontSize: "13px", lineHeight: "18px", margin: "20px 0px" }}>
                                        If you are excited about the prospect of joining our team, please<br /> send your resume and a cover letter detailing your<br /> experience and interest
                                    </p>
                                    <div style={{ margin: "50px 0px", display: "flex", flexDirection: "column", gap: "30px" }}>
                                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                            <PhoneInTalkIcon color="white" />
                                            <span style={{ fontSize: "17px" }}>+91 74161 94172</span>
                                        </div>
                                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                            <EmailIcon color="white" />
                                            <span style={{ fontSize: "17px" }}>info@codeethics.in
                                                <br />nm@codeethics.in</span>
                                        </div>
                                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                            <LocationOnIcon color="white" />
                                            <span style={{ fontSize: "17px" }}>Honer Vivantis, Tellapur Road,<br /> Gopanpally, Hyderabad, Telangana, India</span>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "20px" }}>
                                        <Box display='flex' gap={2}>
                                            <Link to="https://www.instagram.com/ce_codeethics/?utm_source=qr&igsh=dGF0eWJqcmZqeXU2"><InstagramIcon style={{ color: "black" }} color='black' fontSize='1rem' /></Link>
                                            <Link to="https://www.linkedin.com/company/codeethicspvtltd/"><LinkedInIcon style={{ color: "black" }} fontSize='1rem' /></Link>
                                        </Box>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className='right-contact'>
                        <div>
                            <form enctype="multipart/form-data" onSubmit={handleSubmit}>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} md={6}>
                                        <Item>
                                            <TextField required onChange={handleChange('firstName')}
                                                value={formData.firstName} id="standard-basic" label="First Name" variant="standard" style={{ width: "100%" }} />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Item>
                                            <TextField required onChange={handleChange('lastName')}
                                                value={formData.lastName} id="standard-basic" label="Last Name" variant="standard" style={{ width: "100%" }} />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Item>
                                            <TextField type="Email" required onChange={handleChange('email')}
                                                value={formData.email} id="standard-basic" label="Email" variant="standard" style={{ width: "100%" }} />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Item>
                                            <TextField type="Number" required onChange={handleChange('phoneNumber')}
                                                value={formData.phoneNumber} id="standard-basic" label="Phone Number" variant="standard" style={{ width: "100%" }} />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Item>
                                            <TextField required onChange={handleChange('jobRole')}
                                                value={formData.jobRole} id="standard-basic" label="Job Role" variant="standard" style={{ width: "100%" }} />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Item>
                                            <TextField disabled InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <div onClick={handleFileClick} style={{ background: "lightgray", padding: "2.5px 12px", outline: "none", border: "none", cursor: "pointer" }}>Upload</div>
                                                        <input
                                                            type="file"
                                                            id="cv-upload"
                                                            ref={fileInputRef}
                                                            style={{ display: 'none' }}
                                                            onChange={handleFileChange}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }} required onChange={handleChange('cv')}
                                                value={formData.cv} id="standard-basic" label="Upload your CV" variant="standard" style={{ width: "100%" }} />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <TextField required onChange={handleChange('aboutYourself')}
                                                value={formData.aboutYourself} id="standard-basic" label="Pls tell us about yourself" variant="standard" style={{ width: "100%" }} />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Button disabled={submitting} type='submit' style={{ float: "right", background: "white", border: "1px solid #4C9BE8", borderRadius: "0px", color: "#141414", fontWeight: "bold", padding: "7px 16px" }}>{submitting ? 'Sending...' : 'Apply Now'}</Button>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />

        </>
    )
}

export default Career
