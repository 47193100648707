import { Typography, Box, Grid, Button } from '@mui/material'
import React, { useState } from 'react'
import contactHeader from "../images/contact-header.png"
import contactSection from "../images/contact-section.png"
import contactHome from "../images/contact-icon-home.png"
import contactPhone from "../images/contact-icon-phone.png"
import contactMail from "../images/contact-icon-mail.png"
import Footer from './Footer'
import NavBar from './NavBar'
import TextField from '@mui/material/TextField';
// import Textarea from '@mui/material/Textarea';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import EditNoteIcon from '@mui/icons-material/EditNote';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        service: '',
        note: '',
    });
    const [submitting, setSubmitting] = useState(false);
    const handleChange = (field) => (event) => {
        setFormData({
            ...formData,
            [field]: event.target.value,
        });
    };
    const sendEmail = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        try {

            const serviceID = "service_42scdsw";
            const templateID = "template_rsh28zt";

            const params = {
                sendername: `${formData.name}`,
                to: "shruti.pandey@codeethics.in",
                subject: "Enquiry Form Details",
                replyto: formData.email,
                message: `
                          Name: ${formData.name}
                          Mobile Number: ${formData.phone}
                          Email: ${formData.email}
                          Service: ${formData.service}
                          Note:${formData.note}
                        `,
            };
            await emailjs.send(serviceID, templateID, params, "rLi115x7NbmnZdlX-");
            toast.success('Email sent successfully!');
        } catch (error) {
            console.error("Error sending email:", error);
        } finally {
            setSubmitting(false);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (formData) {
            sendEmail(event)
        }
    };

    return (
        <>
            <Box style={{ position: "relative" }} className='social-links-header'>
                <img src={contactHeader} alt="" style={{ width: "100%", height: "43vh" }} />
                <div style={{ position: "absolute" }} className='flex'>
                    <Typography className='t2' sx={{ fontSize: { md: "2rem", sm: "1.5rem", sx: "1rem" } }} fontWeight='700'><span className="code">Contact Us</span></Typography>
                    <Typography className='t1' style={{ color: "#51565E" }} sx={{ fontSize: { md: "0.8rem", sm: "1.5rem", sx: "1rem" } }} fontWeight='700'>Reach out to Code Ethics Webtech for a seamless collaboration. Our dedicated team is here to address your inquiries, discuss your digital needs, and pave the way for your business growth. Contact us today and let's embark on your digital journey together.</Typography>
                </div>
                <div style={{ position: "absolute", top: "0px", left: "0px", right: "0px" }}>
                    <NavBar />
                </div>
            </Box>
            <ToastContainer position="top-right" autoClose={5000} />
            <Box style={{ margin: "60px 0px" }}>
                <Grid container justifyContent='center' spacing={6}>
                    <Grid item md={6} sm={10} lg={4} sx={{ display: "grid", placeItems: "center" }}>
                        <img src={contactSection} alt="" className='contact-section-image' />
                    </Grid>
                    <Grid item md={5} sm={10} xs={10} >
                        <Typography color="#4C9BE8" fontSize='1.2rem'>Get In Touch !</Typography>
                        <Typography style={{ lineHeight: "45px" }} fontSize="2.4rem" fontWeight='700'>We are here to help you always...</Typography>
                        <Box mt={4} gap={2} display='flex' flexDirection='column'>
                            <Grid style={{ display: "flex", alignItems: "center" }} container >
                                <Grid item xs={12} sm={2} md={2.5} lg={2}>
                                    <Box>
                                        <img src={contactHome} alt="" style={{ width: "3.5rem" }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <Box>
                                        <Typography fontSize="1.2rem" fontWeight='700'>Visit Us:</Typography>
                                        <div style={{display:"flex",gap:"10px"}}>
                                        <Typography style={{ width: "300px" }}>Honer Vivantis, Tellapur Road, Gopanpally,<span style={{fontWeight:"bold"}}>Hyderabad ,Telangana, India</span> </Typography>
                                        <Typography style={{ width: "300px" }}>Building A1, Dubai Digital Park, Dubai Silicon Oasis, <span style={{fontWeight:"bold"}}>Dubai, United Arab Emirates</span></Typography>
                                        </div>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid style={{ display: "flex", alignItems: "center" }} container >
                                <Grid item xs={12} sm={2} md={2.5} lg={2}>
                                    <Box>
                                        <img src={contactMail} alt="" style={{ width: "3.5rem" }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={7}>
                                    <Box>
                                        <Typography fontSize="1.2rem" fontWeight='700'>Drop Us:</Typography>
                                        <Typography >info@codeethics.in</Typography>
                                        <Typography >nm@codeethics.in</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid style={{ display: "flex", alignItems: "center" }} container >
                                <Grid item xs={12} sm={2} md={2.5} lg={2}>
                                    <Box>
                                        <img src={contactPhone} alt="" style={{ width: "3.5rem" }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={7}>
                                    <Box>
                                        <Typography fontSize="1.2rem" fontWeight='700'>Call Us:</Typography>
                                        <Typography >+91 74161 94172</Typography>
                                        <Typography>+971 55 788 6713 </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className='contactUsBack'>
                <Box pt={5} pb={10}>
                    <Typography textAlign='center' fontSize='2rem' fontWeight='700' my={3}>Contact Us</Typography>
                    <form onSubmit={handleSubmit}>
                        <Box>
                            <Grid container justifyContent='center'>
                                <Grid item xs={11} md={8} bgcolor='white' p={3} >
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                        <TextField
                                            required
                                            value={formData.name}
                                            onChange={handleChange('name')}
                                            fullWidth
                                            placeholder="Name"
                                            InputProps={{
                                                startAdornment: (
                                                    <PersonOutlineIcon
                                                        color="action"
                                                        sx={{ marginRight: 1, marginLeft: 0.5, color: "#4C9BE8" }}
                                                    />
                                                ),
                                            }}
                                        />
                                        <TextField
                                            required
                                            type='Email'
                                            value={formData.email}
                                            onChange={handleChange('email')}
                                            fullWidth
                                            placeholder="Email"
                                            InputProps={{
                                                startAdornment: (
                                                    <MailOutlineIcon
                                                        color="action"
                                                        sx={{ marginRight: 1, marginLeft: 0.5, color: "#4C9BE8" }}
                                                    />
                                                ),
                                            }}
                                        />
                                        <TextField
                                            type='Number'
                                            required
                                            value={formData.phone}
                                            onChange={handleChange('phone')}
                                            fullWidth
                                            placeholder="Phone"
                                            InputProps={{
                                                startAdornment: (
                                                    <PhoneIcon
                                                        color="action"
                                                        sx={{ marginRight: 1, marginLeft: 0.5, color: "#4C9BE8" }}
                                                    />
                                                ),
                                            }}

                                        />
                                        <TextField
                                            required
                                            value={formData.service}
                                            onChange={handleChange('service')}
                                            fullWidth
                                            placeholder="Service You Need"
                                            InputProps={{
                                                startAdornment: (
                                                    <ImportContactsIcon
                                                        color="action"
                                                        sx={{ marginRight: 1, marginLeft: 0.5, color: "#4C9BE8" }}
                                                    />
                                                ),
                                            }}
                                        />
                                        <TextField
                                            required
                                            value={formData.note}
                                            onChange={handleChange('note')}
                                            InputProps={{
                                                startAdornment: (
                                                    <EditNoteIcon
                                                        color="action"
                                                        sx={{ marginRight: 1, marginLeft: 0.5, color: "#4C9BE8", marginTop: "-3rem" }}
                                                    />
                                                ),
                                            }}
                                            placeholder="Any Note For Us"
                                            multiline
                                            rows={3}
                                            maxRows={Infinity}
                                        />
                                        <Button disabled={submitting} type="submit" variant="outlined" style={{ border: "1px solid #4C9BE8", color: "Black", padding: "0.7rem 0", fontWeight: "700" }}>{submitting ? 'Sending...' : 'SEND ENQUIRY'}</Button>

                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </Box>
            <div style={{ position: "absolute", width: "100%", marginTop: "-3rem" }}>
                <Footer />
            </div>
        </>
    )
}

export default Contact