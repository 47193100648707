import React from 'react'

const DotAnimation = () => {
    return (
        <>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
            <div style={{ zIndex: "999px" }} class="firefly"></div>
        </>

    )
}

export default DotAnimation
