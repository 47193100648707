
import React from 'react'
import logo from "../images/logo.png"
import { Link } from 'react-router-dom';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

const Footer = () => {
    return (
        <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
            <section style={{ background: "#081432", color: "white" }}>
                <MDBContainer className='text-center justify-content-center align-items-center text-md-start mt-5 d-flex'>
                    <MDBRow className='mt-3'>
                        <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>
                                <img src={logo} alt="" width={"80px"} height={"80px"} />

                            </h6>
                            <p>
                                Empower your business with cost-effective solutions. From website design to AMS Engagements, we've got you covered.
                            </p>
                        </MDBCol>

                        <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Main Links</h6>
                            <p>
                                <Link to='/' onClick={() => window.scrollTo(0, 0)} className='text-reset'>
                                    Home
                                </Link>
                            </p>
                            <p>
                                <Link to='/service' onClick={() => window.scrollTo(0, 0)} className='text-reset'>
                                    Services
                                </Link>
                            </p>
                            <p>
                                <Link to='/about' onClick={() => window.scrollTo(0, 0)} className='text-reset'>
                                    About Us
                                </Link>
                            </p>
                            <p>
                                <Link to='/testimonial' onClick={() => window.scrollTo(0, 0)} className='text-reset'>
                                    Testimonials
                                </Link>
                            </p>
                        </MDBCol>

                        <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
                            <p>
                                <Link to='/career' onClick={() => window.scrollTo(0, 0)} className='text-reset'>
                                    Careers
                                </Link>
                            </p>
                            <p>
                                <Link to='/social-links' onClick={() => window.scrollTo(0, 0)} className='text-reset'>
                                    Social Links
                                </Link>
                            </p>
                            <p>
                                <Link to='/contact' onClick={() => window.scrollTo(0, 0)} className='text-reset'>
                                    Contact Us
                                </Link>
                            </p>

                        </MDBCol>

                        <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                            <p>
                                
                                <span style={{color:"grey"}}>India Address : <br/></span>
                                Honer Vivantis, Tellapur Road, Gopanpally, Hyderabad, Telangana, India<br/>
                                <span style={{color:"grey"}}>UAE Address : <br/></span>
                                Building A1, Dubai Digital Park, Dubai Silicon Oasis, Dubai, United Arab Emirates
                            </p>
                            <p>
                                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                                info@codeethics.in
                            </p>
                            <p>
                                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                                nm@codeethics.in
                            </p>
                            <p>
                                <MDBIcon color='secondary' icon='phone' className='me-3' /> +91 74161 94172 , +971 55 788 6713
                            </p>

                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>
        </MDBFooter>
    )
}

export default Footer