import { Typography, Box, Grid } from '@mui/material'
import React from 'react'
import qrCode from "../images/qr-code.png"
import instaQrCode from "../images/instaqr.png"
import socialHeader from "../images/social-header.png"
import Footer from './Footer'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import NavBar from './NavBar'
import { Link } from 'react-router-dom'


const SocialLinks = () => {
    return (
        <>
            <Box className='social-links-header'>
                <img src={socialHeader} alt="" style={{ width: "100%" }} className='socialh' />
                <Typography className='psocial' sx={{ fontSize: { md: "2rem", sm: "1.5rem", sx: "1rem", position: "absolute" } }} fontWeight='700'><span className="code">Connect</span> with Code Ethics</Typography>
                <div style={{ position: "absolute", top: "0px", left: "0px", right: "0px" }}>
                    <NavBar />
                </div>
            </Box>
            <Box>
                <Box my={4} sx={{ display: 'grid', placeItems: 'center' }}>
                    <Typography variant='body2' color='#51565E' width='70%' textAlign='center'>At Code Ethics, we believe in staying connected with our community. Follow us on social media platforms to stay updated on the latest trends, industry insights, and exciting updates from our team. Connect with us and be part of our digital journey!</Typography>
                </Box>
                <Grid className='newGrid1' container justifyContent='center' sx={{ my: 7 }} lg={{ my: 8 }}>
                    <Grid item xs={12} sm={5} md={4} sx={{ display: "grid", placeItems: "center" }}>
                        <Box>
                            <img src={qrCode} alt="" width={250} />
                        </Box>
                    </Grid>
                    <Grid item xs={8} sm={5} md={4} sx={{ display: "grid", placeItems: "center" }}>
                        <Box sx={{ background: "linear-gradient(180deg, rgba(1, 5, 9, 0.51) 0%, rgba(76, 155, 232, 0.51) 44.27%, rgba(76, 155, 232, 0) 100%)", height: '100%', padding: "1rem" }}>
                            <LinkedInIcon />
                            <Typography fontSize="1.5rem" fontWeight='700'>LinkedIn</Typography>
                            <Typography my={3}>Stay connected with us on LinkedIn for professional updates, industry news, and opportunities.</Typography>
                            <Link to={"https://in.linkedin.com/company/codeethicspvtltd"}> <Typography style={{ textDecoration: "underline", color: "blue" }}>Code Ethics LinkedIn Page</Typography></Link>
                        </Box>
                    </Grid>
                </Grid>

                <Grid className='newGrid' container justifyContent='center' sx={{ my: 7 }} lg={{ my: 8 }}>
                    <Grid item xs={8} sm={5} md={4} sx={{ display: "grid", placeItems: "center" }}>
                        <Box sx={{ background: "linear-gradient(180deg, rgba(1, 5, 9, 0.51) 0%, rgba(76, 155, 232, 0.51) 44.27%, rgba(76, 155, 232, 0) 100%)", height: '100%', padding: "1rem" }}>
                            <InstagramIcon />
                            <Typography fontSize="1.5rem" fontWeight='700'>Instagram</Typography>
                            <Typography my={3}>Follow us on Instagram for a visual journey showcasing our work, team, and creative endeavors.</Typography>
                            <Link to={"https://www.instagram.com/ce_codeethics/?utm_source=qr&igsh=dGF0eWJqcmZqeXU2"}><Typography style={{ textDecoration: "underline", color: "blue" }}>Code Ethics Instagram Page</Typography></Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={5} md={4} sx={{ display: "grid", placeItems: "center" }}>
                        <Box>
                            <img src={instaQrCode} alt="" width={210} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Footer />
        </>
    )
}

export default SocialLinks