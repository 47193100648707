/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import { Avatar, Box, Card, CardContent, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import NavBar from './NavBar'
import serv from "../images/teamback.png"
import { styled } from '@mui/material/styles';
import visualsImage from "../images/Visuals.png"
import f1 from "../images/Icon.png"
import f2 from "../images/Icon (1).png"
import f3 from "../images/Icon (2).png"
import f4 from "../images/Icon (3).png"
import m2 from "../images/nityusha.jpg"
import Footer from './Footer';
import { Link } from 'react-router-dom';
const About = () => {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    return (
        <>
            <Box style={{ position: "relative",background: "linear-gradient(to left, white 20%, #deedfb 60%, #e1e2e7 100%)" }} className='social-links-header'>
                <img src={serv} alt="" style={{ width: "100%", height: "43vh" }} />
                <div style={{ position: "absolute" }} className='flex'>
                    <Typography className='t2' sx={{ fontSize: { md: "2rem", sm: "1.5rem", sx: "1rem" } }} fontWeight='700'><span className="code">Who</span> We Are</Typography>
                    <Typography className='t1' style={{ color: "#51565E" }} sx={{ fontSize: { md: "0.8rem", sm: "1.5rem", sx: "1rem" } }} fontWeight='700'>Code Ethics is a team of passionate and skilled professionals dedicated to providing innovative digital solutions. Established with the vision of empowering businesses in the online sphere, we bring together creativity, technology, and strategic thinking to drive your success.</Typography>
                </div>
                <div style={{ position: "absolute", top: "0px", left: "0px", right: "0px" }}>
                    <NavBar />
                </div>
            </Box>
            <section id='partners' style={{ margin: "0px 0px",background: "linear-gradient(to left, white 20%, #deedfb 60%, #e1e2e7 100%)" }}>
                <div className='partners'>
                    <div className='head-partner'>
                        <h1><span className='code'>Meet </span>Our Team</h1>
                    </div>

                    <Grid container spacing={3} justifyContent="center" style={{ marginTop: '38px', marginBottom: "38px", gap: '20px' }}>
                        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Card className='boxshadow' style={{ padding: '28px 0px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                <Avatar sx={{ width: 76, height: 76 }} >SK</Avatar>
                                <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                                        Sarah K
                                    </Typography>
                                    <Typography sx={{ fontSize: 13 }} color="text.secondary" gutterBottom>
                                        Lead designer
                                    </Typography>
                                    <Typography mt={3} sx={{ fontSize: 13, textAlign: 'center' }}>
                                        With a passion for color and a love for clean lines, Sarah brings all our wildest design dreams to life.
                                    </Typography>
                                </CardContent>
                                <Box mt={3} style={{ display: 'flex', gap: '19px', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: 13, color: '#009379', fontWeight: 'bold' }}>Twitter</Typography>
                                    <Typography sx={{ fontSize: 13, color: '#009379', fontWeight: 'bold' }}>Linkedin</Typography>
                                    <Typography sx={{ fontSize: 13, color: '#009379', fontWeight: 'bold' }}>Instagram</Typography>
                                </Box>
                            </Card>
                        </Grid> */}

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Card className='boxshadow' style={{ padding: '28px 0px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                <Avatar sx={{ width: 76, height: 76 }} alt="Remy Sharp" src={m2} />
                                <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                                        Nityusha Mateti
                                    </Typography>
                                    <Typography sx={{ fontSize: 13 }} color="text.secondary" gutterBottom>
                                        Founder & Head of Operations
                                    </Typography>
                                    <Typography mt={3} sx={{ fontSize: 13, textAlign: 'center' }}>
                                        Seasoned QA Consultant with 8+ years, ISTQB & TOSCA certified, devoted to elevating customer experiences.
                                    </Typography>
                                </CardContent>
                                <Box mt={3} style={{ display: 'flex', gap: '19px', justifyContent: 'space-between' }}>
                                    <Link style={{textDecoration:"none"}} to="https://www.linkedin.com/in/nityusha-mateti-64821a127/"><Typography sx={{ fontSize: 13, color: '#009379', fontWeight: 'bold' }}>Linkedin</Typography></Link>
                                    {/* <Link style={{textDecoration:"none"}} to="https://www.instagram.com/nityushaphani/?igsh=MW80OG8zc3FseHBiMw%3D%3D"><Typography sx={{ fontSize: 13, color: '#009379', fontWeight: 'bold' }}>Instagram</Typography></Link> */}
                                </Box>
                            </Card>
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Card className='boxshadow' style={{ padding: '28px 0px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                <Avatar sx={{ width: 76, height: 76 }} >LM</Avatar>
                                <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                                        Lauren M.
                                    </Typography>
                                    <Typography sx={{ fontSize: 13 }} color="text.secondary" gutterBottom>
                                        UX Designer
                                    </Typography>
                                    <Typography mt={3} sx={{ fontSize: 13, textAlign: 'center' }}>
                                        Lauren is the mastermind behind all the seamless user experiences you love. She's a wizard at making things intuitive.
                                    </Typography>
                                </CardContent>
                                <Box mt={3} style={{ display: 'flex', gap: '19px', justifyContent: 'space-between' }}>
                              
                                    <Typography sx={{ fontSize: 13, color: '#009379', fontWeight: 'bold' }}>Linkedin</Typography>
                                    <Typography sx={{ fontSize: 13, color: '#009379', fontWeight: 'bold' }}>Instagram</Typography>
                                </Box>
                            </Card>
                        </Grid> */}
                    </Grid>

                </div>
            </section>
            <section id="features">
                <Grid item container p={5}>
                    <Grid style={{ justifyContent: 'center', display: "flex" }} item xs={12} lg={6}>
                        <img className='featureImage' src={visualsImage} style={{
                            maxHeight: '450px',
                            objectFit: 'cover',
                        }} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box>
                            <Typography my={1} mb={3} fontSize={"2rem"} fontWeight={"bold"}>Our <span className='code1'>Features</span></Typography>
                            <p>
                                Empowering Success Through Innovative Solutions and <br /> Expertise - Explore Our Distinctive Features.
                            </p>
                        </Box>
                        <Grid container spacing={2} my={4}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Item style={{ display: "flex", alignItems: "center",  gap: "20px" }}>
                                    <img src={f1} alt="Your Image" style={{ maxWidth: '100%', height: '40px' }} />
                                    <Typography style={{fontSize:"0.95rem"}}>Innovative Solutions</Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Item style={{ display: "flex", alignItems: "center",  gap: "20px" }}>
                                    <img src={f2} alt="Your Image" style={{ maxWidth: '100%', height: '40px' }} />
                                    <Typography style={{fontSize:"0.95rem"}}>Expert Team</Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Item style={{ display: "flex", alignItems: "center",  gap: "20px" }}>
                                    <img src={f4} alt="Your Image" style={{ maxWidth: '100%', height: '40px' }} />
                                    <Typography style={{fontSize:"0.95rem"}}>Responsive Designs</Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Item style={{ display: "flex", alignItems: "center",  gap: "20px" }}>
                                    <img src={f3} alt="Your Image" style={{ maxWidth: '100%', height: '40px' }} />
                                    <Typography style={{fontSize:"0.95rem"}}>Robust Development</Typography>
                                </Item>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            <div style={{ position: "absolute", width: "100%", marginTop: "-2rem" }}>
                <Footer />
            </div>
        </>
    )
}

export default About
