import { Box, Typography, Grid, Avatar } from '@mui/material'
import React from 'react'
import NavBar from './NavBar'
import serv from "../images/services.png"
import testimonial from "../images/testimonial.png"
import star from "../images/star.png"
import Footer from './Footer'
const Testimonial = () => {
    return (
        <>
            <Box style={{ position: "relative" }} className='social-links-header'>
                <img src={testimonial} alt="" style={{ width: "100%", height: "43vh", position: "relative", zIndex: "-1" }} />
                <div style={{ position: "absolute" }} className='flex'>
                    <Typography className='t2' sx={{ fontSize: { md: "2rem", sm: "1.5rem", sx: "1rem" } }} fontWeight='700'><span className="code">Our</span> Stories</Typography>
                    <Typography className='t1' style={{ color: "#51565E" }} sx={{ fontSize: { md: "0.8rem", sm: "1.5rem", sx: "1rem" } }} fontWeight='700'>Explore the success stories and accolades from our valued clients. Discover how Code Ethics  has been a catalyst for businesses, transforming their digital landscape. Let our client testimonials speak to our commitment, expertise, and the impact we bring to online ventures.</Typography>
                </div>
                <div style={{ position: "absolute", top: "0px", left: "0px", right: "0px" }}>
                    <NavBar />
                </div>
            </Box>
            <Box id="partners" sx={{ background: "linear-gradient(180deg, #FFFFFF 0%, #E5EAFC 49.99%, #4B61B8 109.99%)", paddingY: "2rem" }}>
                <div className='head-partner'>
                    <h1>Our <span className='code' style={{ color: "transparent", textAlign: "center" }}> Client's Review </span></h1>
                </div>
                {/* <div className='head-partner'>
                    <Typography mt={2} textAlign={"center"} px={2} py={2}>
                        See how our landing page ui kit is making an impact.
                    </Typography>
                </div> */}
                <Box py={3}>
                    <Grid className='maingd' container justifyContent='space-around' >
                        <Grid className='gd' item xs={12} sm={12} md={3}>
                            <Box className='testimonial-clients' bgcolor='white'>
                                <Avatar sx={{ width: 50, height: 50 }} >TI</Avatar>
                                <Typography fontSize="1.2rem" fontWeight='700' mt={1}>Talal Ibrahim</Typography>
                                <Typography color='#374151' fontSize="0.9rem">@BBI</Typography>
                                <Typography fontSize="0.8rem" textAlign='center' mt={2}>"We would like to express my appreciation for Code Ethics professionals. Their expertise, creativity and efficient collaboration significantly enhanced our project. We have received very positive feedback from our client. Thank you for delivering top-notch talent and we look forward to a continued collaboration!</Typography>
                                <Box display='flex' gap={2} py={2}>
                                    <img src={star} width={20} />
                                    <img src={star} width={20} />
                                    <img src={star} width={20} />
                                    <img src={star} width={20} />
                                    <img src={star} width={20} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <div style={{ marginTop: "-49px" }}>
                <Footer />
            </div>

        </>
    )
}

export default Testimonial
